













import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import platformGoods from "./platform-goods.vue";
import selfGoods from "./self-goods.vue";
@Component({
    components: {
        platformGoods,
        selfGoods,
    },
})
export default class GoodsCenter_goods extends Vue {
    activeName: string = "1";

    async created() {
        const t = this.$route.query.t || null;
        if (t) {
            this.activeName = t;
        }
    }
}
