















































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging, deepClone } from "@/utils/util";

import setGoodsPrice from "@/components/set-goods-price.vue";
import transfer from "@/components/transfer.vue";
import {
  getAListOfReviewedItems,
  getBusinessList,
  getCategoryList,
  getLongRangeGoodsBrandLists,
  apiGoodsDetail,
  checkGoods,
  apiGoodsPickOut,
} from "@/api/goods";

import { ApprovalStatus } from "@/utils/type";
import { apiShopLists } from "@/api/shop";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
  components: {
    ExportData,
    setGoodsPrice,
    transfer,
    LsDialog
  },
})
export default class Goods extends Vue {
  $refs!: { pickForm: any; paneTable: any; tableForm: any; priceForm: any };

  searchObj: any = {
    keyword: "",
    category_id: "",
    supplier_id: "",
    goods_type: "",
    brand_id: "",
  };
  tableint: any = "";
  supplierList: any[] = [];
  categoryList: any[] = [];
  typeList: any = [];
  tabCount = {
    all: 0,
    success: 0,
    error: 0,
    wait: 0,
  };
  tag_type = {
    1: "",
    2: "warning",
    3: "danger",
    4: "success",
  };
  rules: any = {
    mcs_batch_supply_price: [
      {
        required: true,
        message: "请输入商品集采价",
        trigger: ["blur", "change"],
      },
    ],
    mcs_batch_supply_num: [
      {
        required: true,
        message: "请输入商品集采数量",
        trigger: ["blur", "change"],
      },
    ],
    mcs_supply_price: [
      {
        required: true,
        message: "请输入商品代发价",
        trigger: ["blur", "change"],
      },
    ],
  };
  uploadShow: Boolean = false;
  loading: Boolean = false;
  tableForm: any = {
    spec_table: [],
  };
  this_details: any[] = [];
  shop_list: any = [];
  brandList: any = [];
  b_loading: boolean = false;
  pager = new RequestPaging();
  activeName: any = "all";

  selectIds: any[] = [];
  multipleSelection: any[] = [];
  dialogPrice: boolean = false;
  dialogGoods: boolean = false;
  price_loading: boolean = false;
  shop_loading: boolean = false;
  dialog_title: string = "";
  is_batch: boolean = false;
  is_pick: boolean = false;
  pickForm: any = {
    id_list: [],
    shop_data: [],
  };
  priceForm: any = {
    shop_price_type: "",
    price_method: "",
    value: {
      price_list: {
        price_type: "1",
        price_ratio: "1",
        price_add_sub: "1",
        price_number: "1",
      },
      price_rounding: "1",
      reserve_decimal: 2,
    },
    sku_list: [
      {
        cost_price: "",
        guide_price: "",
        sku: "",
        sku_name: "",
      },
    ],
  };
  get disabledBtn() {
    return !this.selectIds.length;
  }
  apiGoodsLists = getAListOfReviewedItems;
  getList(page?: number, type?: number): void {
    page && (this.pager.page = page);
    this.tableint = ApprovalStatus[this.activeName];
    this.pager
      .request({
        callback: getAListOfReviewedItems,
        params: {
          seach_type: ApprovalStatus[this.activeName],
          ...this.searchObj,
        },
      })
      .then((res: any) => {
        this.tabCount = res?.extend;
      });
  }

  handleReset() {
    this.searchObj = {
      keyword: "",
      category_id: "",
      supplier_id: "",
    };
    this.getList();
  }
  getGoodsCateList() {
    getCategoryList({}).then((res: any) => {
      this.categoryList = res?.lists;
    });
    getBusinessList({}).then((res: any) => {
      this.supplierList = res;
    });
  }

  // 上传总台
  uploadPrice(ids: number) {
    this.loading = true;
    apiGoodsDetail(ids).then((res) => {
      this.loading = false;
      this.uploadShow = true;
      this.this_details = res;
      this.tableForm.spec_table = res.spec_value_list_platform;
    });
  }
  uploadClose() {
    this.uploadShow = false;
  }
  savePrice() {
    this.$refs.tableForm.validate((valid: boolean) => {
      if (valid) {
        alert("提交");
        this.uploadShow = false;
      } else {
        return this.$message.error("请填写完整表单");
      }
    });
  }
  //查看详情
  showDetails(id: number) {
    this.$router.push({
      path: "/goods/goods_center/self-goods-detail",
      query: {
        id: id,
        page: this.pager.page,
        size: this.pager.size,
      },
    });
  }
  //   审核
  checkGoods(row: any) {
    checkGoods(row).then(() => {
      this.getList();
    });
  }

  checkGoodsError(row: any) {
    this.$prompt("请输入不通过原因", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    })
      .then((res: any) => {
        row.re = res.value;
        checkGoods(row).then(() => {
          this.$message({
            type: "success",
            message: "审核成功",
          });
          this.getList();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "取消审核",
        });
      });
  }

  handleBatchStatus(row: any) {
    row.id = this.selectIds;
    checkGoods(row).then(() => {
      this.getList();
    });
  }

  handleSelect(val: any[]) {
    this.selectIds = val.map((item) => item.id);
    this.multipleSelection = val;
  }

  // 全选商品
  handleselectAll() {
    this.$refs.paneTable.toggleAllSelection();
  }

  // 批量挑选
  pickAllGoods() {
    this.dialog_title = `挑选加入商城（已选中${this.selectIds.length}个商品）`;
    this.is_pick = true;
    this.is_batch = true;
    this.dialogGoods = true;
    this.pickForm = {
      id_list: this.selectIds,
      shop_data: [],
    };
  }
  // 挑选
  pickGoods(item: any) {
    this.dialog_title = "挑选加入商城（已选中1个商品）";
    this.is_pick = true;
    this.is_batch = false;
    this.dialogGoods = true;
    this.pickForm = {
      id_list: [item.id],
      shop_data: [],
    };
    this.priceForm = {
      shop_price_type: "",
      price_method: "",
      value: {
        price_list: {
          price_type: "1",
          price_ratio: "1",
          price_add_sub: "1",
          price_number: "1",
        },
        price_rounding: "1",
        reserve_decimal: 2,
      },
      sku_list: [
        {
          cost_price: "",
          guide_price: "",
          sku: "",
          sku_name: "",
        },
      ],
    };
    // this.priceForm
    let sku_list = item.goods_item.map((v) => {
      return {
        cost_price: v.cost_price,
        guide_price: v.guide_price,
        sku: v.sku,
        sku_name: v.sku_name,
      };
    });
    this.priceForm.sku_list = sku_list;
  }
  // 去改价
  async handleConfirmPick() {
    if (this.pickForm.shop_data.length === 0) {
      this.$message.warning("请选择商城");
      return;
    }
    this.dialog_title = "修改销售价";
    // 批量定价
    if (this.is_batch) {
      this.priceForm = {
        shop_price_type: "",
        price_method: "",
        value: {
          price_list: {
            price_type: "1",
            price_ratio: "1",
            price_add_sub: "1",
            price_number: "1",
          },
          price_rounding: "1",
          reserve_decimal: 2,
        },
        sku_list: [
          {
            cost_price: "",
            guide_price: "",
            sku: "",
            sku_name: "",
          },
        ],
      };
      let price: any = [];
      this.multipleSelection.map((v) => {
        let _min = Number(v.min_cost_price);
        let _max = Number(v.max_cost_price);
        price.push(_min);
        price.push(_max);
      });

      price.sort((a, b) => a - b);
      this.priceForm.sku_list[0].cost_price =
        price[0] + " - " + price[price.length - 1];
      this.dialogPrice = true;
      this.dialogGoods = false;
    }
    this.dialogPrice = true;
    this.dialogGoods = false;
  }
  // 返回选商城
  resetShop() {
    this.dialogPrice = false;
    this.dialogGoods = true;
  }

  async handleConfirm() {
    const valid = await this.$refs.priceForm.validate();
    if (!valid) {
      return;
    }
    this.price_loading = true;
    let data = deepClone({ ...this.priceForm, ...this.pickForm });
    // 固定定价
    if (data.price_method == 2) {
      if (this.is_batch) {
        let _value = data.sku_list.map((v: any) => {
          return {
            price: v.price,
          };
        });
        data.value = _value;
      } else {
        // 单个商品传sku
        let _value: any = {};
        data.sku_list.map((v: any) => {
          _value[v.sku] = {
            sku: v.sku,
            price: v.price,
          };
        });
        data.value = _value;
      }
    }
    delete data.sku_list;
    delete data.goods_id;

    try {
      const res = await apiGoodsPickOut(data);
      this.$message({
        type: "success",
        message: "挑选成功",
      });
      this.price_loading = false;
      this.dialogPrice = false;
      this.getList();
    } catch (error) {
      this.price_loading = false;
    }
  }

  // 商城
  async getShop() {
    this.pager.loading = true;
    const res = await apiShopLists({
      page_type: 0,
      app_id: 10,
    });
    this.pager.loading = false;
    this.shop_list = res.lists;
  }

  // 品牌
  async getBrandLists(name = "") {
    const res = await getLongRangeGoodsBrandLists({
      name: name,
    });
    const lists = res.lists.splice(1, 100);
    this.brandList = lists;
  }

  async searchBrand(query: string) {
    this.b_loading = true;
    await this.getBrandLists(query);
    this.b_loading = false;
  }

  async created() {
    this.pager.page = Number(this.$route.query.page) || 1;
    this.pager.size = Number(this.$route.query.size) || 10;
    await this.getShop();
    this.getBrandLists("");
    this.getGoodsCateList();
    this.getList();
    this.typeList = [
      { table: "待审核", value: 0 },
      { table: "审核失败", value: 1 },
      { table: "审核成功", value: 2 },
    ];
  }
}
