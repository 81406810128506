











































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import setGoodsPrice from "@/components/set-goods-price.vue";
import transfer from "@/components/transfer.vue";
import { apiShopLists } from "@/api/shop";

import {
    getLongRangeGoodsMscGoodsLists,
    apiCategoryLists,
    apiSourceGoodsGroupListsNopage,
    apiSourceGoodsEditMcsGoods,
    apiSourceGoodsDeleteGoods,
    apiSourceGoodsEditMcsGoodsStatus,
    apiGetGoodsPrice,
    apiSetGoodsPrice,
    apiPlatformPickOut,
    getLongRangeGoodsBrandLists,
} from "@/api/goods";
import { deepClone } from "@/utils/util.ts";

@Component({
    components: {
        lsPagination,
        LsDialog,
        setGoodsPrice,
        transfer,
    },
})
export default class MyselectionLibraryIndex extends Vue {
    getLongRangeGoodsMscGoodsLists = getLongRangeGoodsMscGoodsLists;
    $refs!: { priceForm: any; pickForm: any };

    pager: RequestPaging = new RequestPaging();
    count: any = {
        inStore: 0,
        newThisMonth: 0,
        onSale: 0,
        soldOut: 0,
    };
    searchObj: any = {
        status: 0,
        category_id: "", //分类id
        brand_id: "", //品牌id
        goods_name: "", //商品名称
        tag: "",
        mcs_spu: "", //商品spu
        min_price: "", //最低价
        max_price: "", //最高价
        min_profit: "",
        max_profit: "",
    };
    dialogPrice: boolean = false;
    dialogGoods: boolean = false;
    price_loading: boolean = false;
    shop_loading: boolean = false;
    dialog_title: string = "";
    is_batch: boolean = false;
    is_pick: boolean = false;
    shop_list: any = [];
    pickForm: any = {
        id_list: [],
        shop_data: [],
    };
    priceForm: any = {
        shop_price_type: "",
        price_method: "",
        value: {
            price_list: {
                price_type: "1",
                price_ratio: "1",
                price_add_sub: "1",
                price_number: "1",
            },
            price_rounding: "1",
            reserve_decimal: 2,
        },
        sku_list: [
            {
                cost_price: "",
                guide_price: "",
                sku: "",
                sku_name: "",
            },
        ],
    };

    //分类列表
    categoryList: any = [];
    b_loading: boolean = false;
    brandList: any = [];
    //多选框数据
    multipleSelection: any[] = [];

    get statusText() {
        return this.searchObj.status === 1
            ? "是否上架商品？"
            : "下架商品停止售卖，不在商城显示，确认继续？";
    }

    //选品库数据
    getGoodsLists(page?: number) {
        page && (this.pager.page = page);
        const searchObj = this.searchObj;
        const category_arr = searchObj.category_id;
        const data = {
            status: searchObj.status,
            first_category_id: category_arr[0],
            second_category_id: category_arr.length > 1 ? category_arr[1] : "",
            category_id:
                category_arr.length > 2
                    ? category_arr[category_arr.length - 1]
                    : "",
            goods_name: searchObj.goods_name,
            mcs_spu: searchObj.mcs_spu,
            min_price: searchObj.min_price,
            max_price: searchObj.max_price,
            min_profit: searchObj.min_profit,
            max_profit: searchObj.max_profit,
            tag: searchObj.tag,
            brand_id: searchObj.brand_id,
        };

        this.pager
            .request({
                callback: getLongRangeGoodsMscGoodsLists,
                params: {
                    ...data,
                },
            })
            .then((res) => {
                this.count = res.extend;
            });
    }

    //表格全选
    handleSelectionChange(value: any) {
        this.multipleSelection = value;
    }

    // 上下架
    async changeStatus(id: number) {
        const status = this.searchObj.status === 1 ? 1 : 0;
        const id_list = id ? [id] : this.multipleSelection.map((v) => v.id);
        await apiSourceGoodsEditMcsGoodsStatus({
            id_list,
            status,
        });
        this.getGoodsLists();
    }

    //查看详情
    viewDetails(row: any) {
        this.$router.push({
            path: "/goods/goods_center/platform-goods-detail",
            query: {
                id: row.id,
                page: this.pager.page,
                size: this.pager.size,
            },
        });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getGoodsLists(1);
    }

    // 排序
    sortChange({ prop, order }: any) {
        this.searchObj.order = order;
        if (order === "descending") {
            this.searchObj.order = "desc";
        }
        if (order === "ascending") {
            this.searchObj.order = "asc";
        }
        this.searchObj.prop = prop;
        this.getGoodsLists();
    }

    // 批量挑选
    pickAllGoods() {
        this.dialog_title = `挑选加入商城（已选中${this.multipleSelection.length}个商品）`;
        this.is_pick = true;
        this.is_batch = true;
        this.dialogGoods = true;
        const ids = this.multipleSelection.map((v) => v.id);
        this.pickForm = {
            id_list: ids,
            shop_data: [],
        };
    }
    // 挑选
    pickGoods(item: any) {
        this.dialog_title = "挑选加入商城（已选中1个商品）";
        this.is_pick = true;
        this.is_batch = false;
        this.dialogGoods = true;
        this.pickForm = {
            id_list: [item.id],
            shop_data: [],
        };
    }
    // 去改价
    async handleConfirmPick() {
        if (this.pickForm.shop_data.length === 0) {
            this.$message.warning("请选择商城");
            return;
        }
        this.dialog_title = "修改销售价";
        // 批量定价
        if (this.is_batch) {
            this.priceForm = {
                shop_price_type: "",
                price_method: "",
                value: {
                    price_list: {
                        price_type: "1",
                        price_ratio: "1",
                        price_add_sub: "1",
                        price_number: "1",
                    },
                    price_rounding: "1",
                    reserve_decimal: 2,
                },
                sku_list: [
                    {
                        cost_price: "",
                        guide_price: "",
                        sku: "",
                        sku_name: "",
                    },
                ],
            };
            let price: any = [];
            this.multipleSelection.map((v) => {
                let _min = Number(v.min_mcs_agreed_purchase_price);
                let _max = Number(v.max_mcs_agreed_purchase_price);
                price.push(_min);
                price.push(_max);
            });
            price.sort((a, b) => a - b);
            this.priceForm.sku_list[0].cost_price =
                price[0] + " - " + price[price.length - 1];
            this.dialogGoods = false;
            this.dialogPrice = true;
        } else {
            // 单个定价
            this.shop_loading = true;
            try {
                await this.getGoodsPrice(this.pickForm.id_list[0]);
                this.dialogGoods = false;
                this.shop_loading = false;
                this.dialogPrice = true;
            } catch (error) {
                this.shop_loading = false;
            }
        }
    }
    // 返回选商城
    resetShop() {
        this.dialogPrice = false;
        this.dialogGoods = true;
    }

    // 批量改价
    changeAllPrice() {
        this.is_batch = true;
        this.is_pick = false;
        this.priceForm = {
            shop_price_type: "",
            price_method: "",
            value: {
                price_list: {
                    price_type: "1",
                    price_ratio: "1",
                    price_add_sub: "1",
                    price_number: "1",
                },
                price_rounding: "1",
                reserve_decimal: 2,
            },
            sku_list: [
                {
                    cost_price: "",
                    guide_price: "",
                    sku: "",
                    sku_name: "",
                },
            ],
        };
        let price: any = [];
        this.multipleSelection.map((v) => {
            let _min = Number(v.min_mcs_agreed_purchase_price);
            let _max = Number(v.max_mcs_agreed_purchase_price);
            price.push(_min);
            price.push(_max);
        });
        price.sort((a, b) => a - b);
        this.priceForm.sku_list[0].cost_price =
            price[0] + " - " + price[price.length - 1];
        const id_list = this.multipleSelection.map((v) => v.id);
        this.priceForm.id_list = id_list;
        this.dialogPrice = true;
    }
    // 获取商品改价信息
    async getGoodsPrice(id: number) {
        const res = await apiGetGoodsPrice({ id: id });
        this.priceForm = res.goods_price_type;
        this.priceForm.sku_list = res.sku_list;
        if (this.priceForm.price_method == 2) {
            for (const key in this.priceForm.value) {
                this.priceForm.sku_list.map((k) => {
                    if (key == k.sku) {
                        k.price = this.priceForm.value[key].price;
                    }
                });
            }
            this.priceForm.value = {
                price_list: {
                    price_type: "1",
                    price_ratio: "1",
                    price_add_sub: "1",
                    price_number: "1",
                },
                price_rounding: "1",
                reserve_decimal: 2,
            };
        }
        this.priceForm.id_list = [id];
    }
    // 改价
    async changePrice(id: any) {
        this.pager.loading = true;
        this.is_batch = false;
        this.is_pick = false;
        await this.getGoodsPrice(id);
        this.pager.loading = false;
        this.dialogPrice = true;
    }

    async handleConfirm() {
        const valid = await this.$refs.priceForm.validate();
        if (!valid) {
            return;
        }
        this.price_loading = true;

        let data: any = {};
        data = this.is_pick
            ? deepClone({ ...this.priceForm, ...this.pickForm })
            : deepClone(this.priceForm);
        // 固定定价
        if (data.price_method == 2) {
            if (this.is_batch) {
                let _value = data.sku_list.map((v) => {
                    return {
                        price: v.price,
                    };
                });
                data.value = _value;
            } else {
                // 单个商品传sku
                let _value:any = {};
                data.sku_list.map((v) => {
                    _value[v.sku] = {
                        sku: v.sku,
                        price: v.price,
                    };
                });
                data.value = _value;
            }
        }
        delete data.sku_list;
        delete data.goods_id;

        try {
            const res = this.is_pick
                ? await apiPlatformPickOut(data)
                : await apiSetGoodsPrice(data);
            this.$message({
                type: "success",
                message: this.is_pick ? "挑选成功" : "改价成功",
            });
            this.price_loading = false;
            this.dialogPrice = false;
            this.getGoodsLists();
        } catch (error) {
            this.price_loading = false;
        }
    }

    //获取分类
    getCategoryList() {
        apiCategoryLists({ page_type: 1 }).then((res: any) => {
            // res?.lists.forEach((item: any) => {
            //     item.sons &&
            //         item.sons.forEach((sitem: any) => {
            //             delete sitem.sons;
            //         });
            // });
            this.categoryList = res?.lists;
        });
    }

    // 商城
    async getShop() {
        const res = await apiShopLists({
            page_type: 0,
            app_id: 10,
        });
        this.shop_list = res.lists;
    }

    async searchBrand(query: string) {
        this.b_loading = true;
        await this.getBrandLists(query);
        this.b_loading = false;
    }

    // 品牌
    async getBrandLists(name = "") {
        const res = await getLongRangeGoodsBrandLists({
            name: name,
        });
        const lists = res.lists.splice(1, 100);
        this.brandList = lists;
    }

    async getOtherLists() {
        await this.getShop();
        await this.getBrandLists("");
        await this.getCategoryList();
    }
    /** S ods **/
    async created() {
        this.pager.page = Number(this.$route.query.page) || 1;
        this.pager.size = Number(this.$route.query.size) || 10;
        this.getOtherLists();
        this.getGoodsLists(1);
    }
}
